<template>
    <v-container>
        <div class="page-title" style="max-height: 999999px; color: rgb(34, 34, 34); font-family: sans-serif; font-size: 16px;">
<h2 style="font-variant-numeric: normal; font-variant-east-asian: normal; font-weight: 700; font-stretch: normal; font-size: 30px; padding-left: 20px;line-height: normal; font-family: &quot;Roboto Slab&quot;, Arial, Helvetica, sans-serif; margin: 0px 0px 12px; max-height: 999999px; color: rgb(0, 0, 0);">Публичная оферта о предоставлении услуг</h2>
</div>

<div style="max-height: 999999px; color: rgb(34, 34, 34); font-family: sans-serif; font-size: 16px; padding-left: 20px;">
<h3 style="max-height: 999999px;">Основные понятия</h3>

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Посетитель Сайта</span>&nbsp;&mdash; лицо, пришедшее на сайт&nbsp;без цели размещения Заказа.</p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Пользователь</span>&nbsp;&mdash; физическое лицо, посетитель Сайта, принимающий условия настоящего Соглашения и желающий разместить Заказы в Интернет-магазине</p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Покупатель</span>&nbsp;&mdash; Пользователь, разместивший Заказ в Интернет-магазине</p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Продавец</span>&nbsp;&mdash; юридическое лицо, осуществляющее продажу товара. Реквизиты, адрес местонахождения и контактные телефоны указаны в разделе&nbsp;<a href="https://fisolini.ru/contact" style="background-color: transparent; max-height: 999999px;">контакты</a></p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Интернет-магазин</span>&nbsp;&mdash; Интернет-сайт, принадлежащий&nbsp;, расположенный в сети интернет по адресу&nbsp;, где представлены Товары, предлагаемые Продавцами для приобретения, а также условия оплаты и доставки Товаров Покупателям.</p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Сайт&nbsp;</span>&mdash;</p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Товар</span>&nbsp;&mdash;товары, представленные к продаже на Сайте.</p>
&nbsp;

<p style="max-height: 999999px; margin: 0px;"><span style="font-weight: 700; max-height: 999999px;">Заказ</span>&nbsp;&mdash; должным образом оформленный запрос Покупателя на приобретение и доставку по указанному Покупателем адресу / посредством самовывоза Товаров, выбранных на Сайте.</p>
&nbsp;

<h3 style="max-height: 999999px;">1. Общие положения</h3>

<p style="max-height: 999999px; margin: 0px;">1.1. Продавец осуществляет продажу Товаров через Интернет-магазин по адресу</p>

<p style="max-height: 999999px; margin: 0px;">1.2. Заказывая Товары через Интернет-магазин, Пользователь соглашается с условиями продажи Товаров, изложенными ниже (далее; Условия продажи товаров). В случае несогласия с настоящим Пользовательским соглашением (далее &mdash; Соглашение / Публичная оферта) Пользователь обязан немедленно прекратить использование сервиса и покинуть сайт</p>

<p style="max-height: 999999px; margin: 0px;">1.3. Настоящие Условия продажи товаров, а также информация о Товаре, представленная на Сайте, являются публичной офертой в соответствии со ст.435 и п.2 ст.437 Гражданского кодекса Российской Федерации.</p>

<p style="max-height: 999999px; margin: 0px;">1.4. Соглашение может быть изменено Продавцом в одностороннем порядке без уведомления Пользователя/Покупателя. Новая редакция Соглашения вступает в силу по истечении 10 (Десяти) календарных дней с момента ее опубликования на Сайте, если иное не предусмотрено условиями настоящего Соглашения.</p>

<p style="max-height: 999999px; margin: 0px;">1.5. Публичная оферта признается акцептованной Посетителем Сайта / Покупателем с момента оформления Покупателем Заказа на Сайте, а также с момента принятия от Покупателя Заказа по телефонам указанным на сайте</p>

<p style="max-height: 999999px; margin: 0px;">1.6. Договор розничной купли-продажи считается заключенным с момента выдачи Продавцом Покупателю кассового или товарного чека либо иного документа, подтверждающего оплату товара.</p>

<p style="max-height: 999999px; margin: 0px;">1.7. Сообщая Продавцу свой e-mail и номер телефона, Посетитель Сайта/Пользователь/Покупатель дает согласие на использование указанных средств связи Продавцом, а также третьими лицами, привлекаемыми им для целей выполнения обязательств перед Посетителями Сайта/Пользователями/Покупателями, в целях осуществления рассылок рекламного и информационного характера, содержащих информацию о скидках, предстоящих и действующих акциях и других мероприятиях Продавца, о передаче заказа в доставку, а также иную информацию, непосредственно связанную с выполнением обязательств Покупателем в рамках настоящей Публичной оферты.</p>

<p style="max-height: 999999px; margin: 0px;">1.8. Осуществляя Заказ, Пользователь/Покупатель соглашается с тем, что Продавец может поручить исполнение Договора третьему лицу, при этом оставаясь ответственным за его исполнение.</p>

<p style="max-height: 999999px; margin: 0px;">1.9. Все права и обязательства по заключенному с Пользователем Договору возникают непосредственно у Продавца, при этом Покупатель, принимая настоящее Соглашение, полностью понимает и соглашается, что в случае заключения договора с Продавцом, отличным от&nbsp;,&nbsp;не является стороной указанного договора и не несет обязанностей, связанных с его исполнением, кроме предусмотренных настоящей Публичной офертой.</p>

<p style="max-height: 999999px; margin: 0px;">1.10.&nbsp;не несет ответственности за точность и правильность информации, предоставляемой Пользователем.</p>

<p style="max-height: 999999px; margin: 0px;">1.11.Вы можете оформить заказ в Интернет-магазине&nbsp;24 часа в сутки, 7 дней в неделю, кроме периодов проведения регламентных работ или технических сбоев.</p>

<p style="max-height: 999999px; margin: 0px;">1.12. К отношениям между Посетителем Сайта / Пользователем / Покупателем и Продавцом, в т.ч. в части порядка заключения и расторжения договора купли-продажи, применяются положения Закона РФ от 07.02.1992 N 2300-1 &quot;О защите прав потребителей&quot;, Правила продажи товаров дистанционным способом (Постановление Правительства РФ от 27.09.2007 N 612), а также положения Гражданского кодекса Российской Федерации.</p>

<h3 style="max-height: 999999px;">2. Предмет соглашения</h3>

<p style="max-height: 999999px; margin: 0px;">2.1. Предметом настоящего Соглашения является предоставление возможности Пользователю приобретать для личных, семейных, домашних и иных нужд, не связанных с осуществлением предпринимательской деятельности, Товары, представленные в каталоге Интернет-магазина по адресу</p>

<p style="max-height: 999999px; margin: 0px;">2.2. Данное Соглашение распространяется на все виды Товаров и услуг, представленных на Сайте, пока такие предложения с описанием присутствуют в каталоге Интернет-магазина</p>

<h3 style="max-height: 999999px;">3. Товар и порядок совершения покупки</h3>

<p style="max-height: 999999px; margin: 0px;">3.1. Продавец обеспечивает наличие на своем складе Товаров, представленных на Сайте. Сопровождающие Товар фотографии являются простыми иллюстрациями к нему и могут отличаться от фактического внешнего вида Товара. Сопровождающие Товар описания/характеристики не претендуют на исчерпывающую информативность и могут содержать опечатки. Для уточнения информации по Товару Покупатель должен обратиться в Службу поддержки клиентов по телефонам указанным на Сайте.</p>

<p style="max-height: 999999px; margin: 0px;">3.2. Покупатель несет полную ответственность за предоставление неверных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем.</p>

<p style="max-height: 999999px; margin: 0px;">3.3. Ожидаемая дата передачи Заказа в Службу доставки сообщается Покупателю менеджером, обслуживающим Заказ, по SMS или при контрольном звонке Покупателю. Дата передачи Товара может быть изменена Продавцом в одностороннем порядке в случае наличия объективных, по мнению Продавца, причин.</p>

<h3 style="max-height: 999999px;">4. Доставка заказа</h3>

<p style="max-height: 999999px; margin: 0px;">4.1. Способы, а также примерные сроки доставки Товаров, реализуемых&nbsp;, указаны на Сайте в разделе&nbsp;<a href="https://fisolini.ru/payment" style="background-color: transparent; max-height: 999999px;">&laquo;Способы доставки&raquo;</a>.</p>

<p style="max-height: 999999px; margin: 0px;">4.2. Территория доставки Товаров, представленных на Сайте&nbsp;и реализуемых, ограничена пределами Российской Федерации.</p>

<p style="max-height: 999999px; margin: 0px;">4.3. Задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца.</p>

<p style="max-height: 999999px; margin: 0px;">4.4. При доставке Заказ вручается Покупателю либо третьему лицу, указанному в Заказе в качестве получателя (далее Покупатель и третье лицо именуются &laquo;Получатель&raquo;). При невозможности получения Заказа, оплаченного посредством наличного расчета, указанными выше лицами, Заказ может быть вручен лицу, который может предоставить сведения о Заказе (номер отправления и/или ФИО Получателя), а также оплатить стоимость Заказа в полном объеме лицу, осуществляющему доставку Заказа.</p>

<p style="max-height: 999999px; margin: 0px;">4.5. Риск случайной гибели или случайного повреждения Товара переходит к Покупателю с момента передачи ему Заказа и проставления Получателем Заказа подписи в документах, подтверждающих доставку Заказа.</p>

<p style="max-height: 999999px; margin: 0px;">4.6. Стоимость доставки каждого Заказа рассчитывается индивидуально, исходя из веса Товара, региона и способа доставки, а также (в случае необходимости) формы оплаты, и указывается на последнем этапе оформления.</p>

<p style="max-height: 999999px; margin: 0px;">4.7. Обязанность Продавца передать товар Покупателю считается исполненной в момент вручения курьером Товара Получателю или получения Товара Получателем в отделении почтовой связи.</p>

<p style="max-height: 999999px; margin: 0px;">4.8 При получении Заказа в отделении почтовой связи Получатель после оплаты доставленного Товара обязан осмотреть доставленный Товар и произвести его вскрытие в присутствии работников Почты России для проверки Товара на соответствие заявленному количеству, ассортименту и комплектности Товара, а также целостность упаковки. В случае наличия претензий к доставленному Товару (недовложение, вложение Товара отличного от указанного в заказе, производственный брак, иные претензии) по указанию Получателя работниками Почты России составляется Акт о выявленных несоответствиях. Если Получателем не были заявлены претензии в вышеуказанном порядке, то Продавец считается полностью и надлежащим образом исполнившим свою обязанность по передаче Товара.</p>

<p style="max-height: 999999px; margin: 0px;">4.9 В случае возврата доставленного посредством Почты России Товара в связи с наличием претензий к Товару Получатель обязан приложить к Отправлению, содержащему возвращаемый Товар, следующие документы:</p>

<p style="max-height: 999999px; margin: 0px;">- Заявление на возврат денежных средств;</p>

<p style="max-height: 999999px; margin: 0px;">- Копию акта о выявленных несоответствиях;</p>

<p style="max-height: 999999px; margin: 0px;">- Копию квитанции об оплате;</p>

<p style="max-height: 999999px; margin: 0px;">- Копию описи Отправления;</p>

<p style="max-height: 999999px; margin: 0px;">- Бланк возврата.</p>

<p style="max-height: 999999px; margin: 0px;">4.10. При принятии Заказа от курьера, Получатель обязан осмотреть доставленный Товар и проверить его на соответствие заявленному количеству, ассортименту и комплектности Товара, а также проверить срок службы доставленного Товара и целостность упаковки. В случае отсутствия претензий к доставленному Товару Получатель расписывается в &laquo;Бланке доставки заказов&raquo; либо ином аналогичном документе, предоставляемом курьером, и оплачивает Заказ. Подпись в доставочных документах свидетельствует о том, что претензий к Товару Получателем не заявлено и Продавец полностью и надлежащим образом выполнил свою обязанность по передаче Товара.</p>

<p style="max-height: 999999px; margin: 0px;">4.11.Уточнить дату, время и при необходимости маршрут доставки, можно у менеджера, который связывается с Покупателем для подтверждения Заказа.</p>

<p style="max-height: 999999px; margin: 0px;">4.12. Пользователь понимает и соглашается с тем, что: осуществление доставки &mdash; отдельная услуга, не являющаяся неотъемлемой частью приобретаемого Покупателем Товара, выполнение которой заканчивается в момент получения Получателем Товара и осуществления платежа за него.Претензии к качеству приобретенного Товара, возникшие после получения и оплаты Товара, рассматриваются в соответствии с Законом РФ &laquo;О защите прав потребителей&raquo; и гарантийными обязательствами соответствующего Продавца. В связи с этим приобретение Товара с доставкой не дает Покупателю право требования доставки приобретенного Товара в целях гарантийного обслуживания или замены, не дает возможности осуществлять гарантийное обслуживание или замену Товара посредством выезда к Покупателю и не подразумевает возможность возврата стоимости доставки Товара в случаях, когда Покупатель имеет право на возврат денег за Товар как таковой, в соответствии с Законом РФ &laquo;О защите прав потребителей&raquo;.</p>

<h3 style="max-height: 999999px;">5. Оплата товара</h3>

<p style="max-height: 999999px; margin: 0px;">5.1. Цена товара, реализуемого в Интернет-магазине, указывается в рублях Российской Федерации и включает в себя налог на добавленную стоимость.</p>

<p style="max-height: 999999px; margin: 0px;">5.2. Цена Товара указывается на Сайте. В случае неверного указания цены заказанного Покупателем Товара, Продавец информирует об этом Покупателя для подтверждения Заказа по исправленной цене либо аннулирования Заказа. При невозможности связаться с Покупателем данный Заказ считается аннулированным.</p>

<p style="max-height: 999999px; margin: 0px;">5.3. Цена Товара на Сайте может быть изменена Продавцом в одностороннем порядке. При этом цена на заказанный Покупателем Товар изменению не подлежит. Цена Товара может дифференцироваться по регионам.</p>

<p style="max-height: 999999px; margin: 0px;">5.4. Особенности оплаты Товара с помощью банковских карт:</p>

<p style="max-height: 999999px; margin: 0px;">5.4.1 В соответствии с положением ЦБ РФ &laquo;Об эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт&raquo; от 24.12.2004 № 266-П операции по банковским картам совершаются держателем карты либо уполномоченным им лицом.</p>

<p style="max-height: 999999px; margin: 0px;">5.4.2 Авторизация операций по банковским картам осуществляется банком. Если у банка есть основания полагать, что операция носит мошеннический характер, то банк вправе отказать в осуществлении данной операции. Мошеннические операции с банковскими картами попадают под действие статьи 159 УК РФ.</p>

<p style="max-height: 999999px; margin: 0px;">5.5.&nbsp;вправе предоставлять скидки на Товары и устанавливать программу бонусов.</p>

<p style="max-height: 999999px; margin: 0px;">В случае если при применении скидки / бонуса пересчитанная стоимость Товара включает в себя копейки, такая стоимость Товара подлежит округлению в сторону уменьшения до значения, кратного 1 (Одному) рублю.</p>

<p style="max-height: 999999px; margin: 0px;">5.6. При проведении маркетинговых мероприятий, предполагающих вложение каких-либо объектов в отправления с Заказом Покупателя, доставка указанных вложений осуществляется за счет Покупателя. Для того, чтобы отказаться от вложения, Покупателю необходимо обратиться в Службу по работе с клиентами.</p>

<p style="max-height: 999999px; margin: 0px;">5.7. Продавец ведет статистику выкупленных Покупателем заказов. Продавец вправе в одностороннем порядке определять способы оплаты, доступные соответствующему Покупателю, основываясь на статистике действий, совершенных Покупателем во взаимоотношениях с Продавцом.</p>

<h3 style="max-height: 999999px;">6. Возврат товара и денежных средств</h3>

<p style="max-height: 999999px; margin: 0px;">6.1. Возврат Товара, реализуемого&nbsp;, осуществляется в соответствии с указанными на Сайте&nbsp;<a href="https://fisolini.ru/payment" style="background-color: transparent; max-height: 999999px;">&laquo;Условиями возврата&raquo;</a></p>

<p style="max-height: 999999px; margin: 0px;">6.2. Возврат Товара надлежащего качества</p>

<p style="max-height: 999999px; margin: 0px;">6.2.1. Покупатель вправе отказаться от заказанного Товара в любое время до его получения, а после получения Товара &mdash; в течение 14 дней (либо более длительного срока для отдельных категорий товаров), не считая дня покупки. Возврат Товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного Товара.</p>

<p style="max-height: 999999px; margin: 0px;">6.2.2. При отказе Покупателя от Товара согласно п.6.2.1. Продавец возвращает ему стоимость возвращенного Товара, за исключением расходов Продавца, связанных с доставкой возвращенного Покупателем Товара, в течение 10 дней с даты поступления возвращенного Товара на склад Продавца вместе с заполненным Покупателем заявлением на возврат.</p>

<p style="max-height: 999999px; margin: 0px;">6.3. Возврат Товара ненадлежащего качества:</p>

<p style="max-height: 999999px; margin: 0px;">6.3.1. Под товаром ненадлежащего качества подразумевается товар, который неисправен и не может обеспечить исполнение своих функциональных качеств. Полученный Товар должен соответствовать описанию на Сайте. Отличие элементов дизайна или оформления от заявленного на Сайте описания не является признаком ненадлежащего качества.</p>

<p style="max-height: 999999px; margin: 0px;">6.3.2. Внешний вид и комплектность Товара, а также комплектность всего Заказа должны быть проверены Получателем в момент доставки Товара.</p>

<p style="max-height: 999999px; margin: 0px;">6.3.3. При доставке Товара Покупатель ставит свою подпись в квитанции о доставке в графе:&nbsp;<i style="max-height: 999999px;">&laquo;Заказ принял, комплектность полная, претензий к количеству и внешнему виду товара не имею&raquo;</i>, либо в ином аналогичном документе, выпускаемого Продавцом, в графе, предусматривающей проставление Покупателем отметки об отсутствии у него претензий к комплектности, количеству и качеству Товара. После получения Заказа претензии к внешним дефектам товара, его количеству, комплектности и товарному виду не принимаются.</p>

<p style="max-height: 999999px; margin: 0px;">6.3.4. Если Покупателю был передан Товар ненадлежащего качества и оное не было заранее оговорено Продавцом, Покупатель вправе воспользоваться положениями ст. 18 &laquo;Права потребителя при обнаружении в товаре недостатков&raquo; Закона о защите прав потребителей.</p>

<p style="max-height: 999999px; margin: 0px;">6.3.5. Требования о возврате уплаченной за товар денежной суммы подлежат удовлетворению в течение 10 дней со дня предъявления соответствующего требования (ст. 22 Закона РФ &laquo;О защите прав потребителей&raquo;).</p>

<p style="max-height: 999999px; margin: 0px;">6.4. Возврат денежных средств осуществляется посредством возврата стоимости оплаченного Товара на банковскую карту или почтовым переводом.</p>

<h3 style="max-height: 999999px;">7. Ответственность</h3>

<p style="max-height: 999999px; margin: 0px;">7.1. Продавец не несет ответственности за ущерб, причиненный Покупателю вследствие ненадлежащего использования Товаров, приобретенных в Интернет-магазине.</p>

<h3 style="max-height: 999999px;">8. Конфиденциальность и защита информации</h3>

<p style="max-height: 999999px; margin: 0px;">8.1. Персональные данные Пользователя/Покупателя обрабатывается в соответствии с ФЗ &laquo;О персональных данных&raquo; № 152-ФЗ</p>

<p style="max-height: 999999px; margin: 0px;">8.2. Предоставляя свои персональные данные Продавцу, Посетитель Сайта/Пользователь/Покупатель соглашается на их обработку Продавцом, в том числе в целях выполнения Продавцом обязательств перед Посетителем Сайта/Пользователем/Покупателем в рамках настоящей Публичной оферты , продвижения Продавцом товаров и услуг, проведения электронных и sms опросов, контроля результатов маркетинговых акций, клиентской поддержки, организации доставки товара Покупателям, проведение розыгрышей призов среди Посетителей Сайта/Пользователей/Покупателей, контроля удовлетворенности Посетителя Сайта/Пользователя/Покупателя, а также качества услуг, оказываемых Продавцом.</p>

<p style="max-height: 999999px; margin: 0px;">8.3. Под обработкой персональных данных понимается любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение) извлечение, использование, передачу (в том числе передачу третьим лицам, не исключая трансграничную передачу, если необходимость в ней возникла в ходе исполнения обязательств), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>

<p style="max-height: 999999px; margin: 0px;">8.4.1. Продавец имеет право отправлять информационные, в том числе рекламные сообщения, на электронную почту и мобильный телефон Пользователя/Покупателя с его согласия, выраженного посредством совершения им действий, однозначно идентифицирующих этого абонента и позволяющих достоверно установить его волеизъявление на получение сообщения. Пользователь/Покупатель вправе отказаться от получения рекламной и другой информации без объяснения причин отказа путем информирования&nbsp;о своем отказе по телефону-8 (800) 707-06-52.Сервисные сообщения, информирующие Пользователя/Покупателя о заказе и этапах его обработки, отправляются автоматически и не могут быть отклонены Пользователем/Покупателем.</p>

<p style="max-height: 999999px; margin: 0px;">8.4.2 Отзыв согласия на обработку персональных данных осуществляется путем отзыва акцепта настоящей Публичной оферты по телефону-8 (800) 707-06-52</p>

<p style="max-height: 999999px; margin: 0px;">8.5. Продавец вправе использовать технологию &laquo;cookies&raquo;. &laquo;Cookies&raquo; не содержат конфиденциальную информацию. Посетитель / Пользователь / Покупатель настоящим дает согласие на сбор, анализ и использование cookies, в том числе третьими лицами для целей формирования статистики и оптимизации рекламных сообщений.</p>

<p style="max-height: 999999px; margin: 0px;">8.6. Продавец получает информацию об ip-адресе посетителя Сайта&nbsp;. Данная информация не используется для установления личности посетителя.</p>

<p style="max-height: 999999px; margin: 0px;">8.7. Продавец не несет ответственности за сведения, предоставленные Пользователем/Покупателем на Сайте в общедоступной форме.</p>

<p style="max-height: 999999px; margin: 0px;">8.8. Продавец вправе осуществлять записи телефонных разговоров с Пользователем/Покупателем. При этом Продавец обязуется: предотвращать попытки несанкционированного доступа к информации, полученной в ходе телефонных переговоров, и/или передачу ее третьим лицам, не имеющим непосредственного отношения к исполнению Заказов, в соответствии с п. 4 ст. 16 Федерального закона &laquo;Об информации, информационных технологиях и о защите информации&raquo;.</p>

<h3 style="max-height: 999999px;">9. Срок действия Публичной оферты</h3>

<p style="max-height: 999999px; margin: 0px;">9.1 Настоящая Публичная оферта вступает в силу с момента ее акцепта Посетителем Сайта/Покупателем, и действует до момента отзыва акцепта Публичной оферты.</p>

<h3 style="max-height: 999999px;">10. Дополнительные условия</h3>

<p style="max-height: 999999px; margin: 0px;">10.1. Продавец вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.</p>

<p style="max-height: 999999px; margin: 0px;">10.2. Интернет-магазин и предоставляемые сервисы могут временно частично или полностью недоступны по причине проведения профилактических или иных работ, или по любым другим причинам технического характера. Техническая служба&nbsp;имеет право периодически проводить необходимые профилактические или иные работы с предварительным уведомлением Покупателей или без такового.</p>

<p style="max-height: 999999px; margin: 0px;">10.3. К отношениям между Пользователем/Покупателем и Продавцом применяются положения Российского законодательства.</p>

<p style="max-height: 999999px; margin: 0px;">10.4. В случае возникновения вопросов и претензий со стороны Пользователя/Покупателя он должен обратиться к Продавцу по телефону или иным доступным способом. Все возникающее споры стороны будут стараться решить путем переговоров, при не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с действующим законодательством РФ.</p>

<p style="max-height: 999999px; margin: 0px;">10.5. Признание судом недействительности какого-либо положения настоящего Соглашения не влечет за собой недействительность остальных положений.</p>
</div>

    </v-container>
</template>
<script>
    
export default {
  metaInfo:{
    title: 'Публичная оферта',
    meta: [
      {
        name: 'description',
                 content: 'Заказывая Товары через Интернет-магазин, Пользователь соглашается с условиями продажи Товаров, изложенными ниже (далее; Условия продажи товаров).',
      },
      {
        name: 'keywords',
        content: 'Ковры, fisolini, фисолини, компания фисолини, ковры fisolini.ru, ковры'
      }
    ]
  },
  data(){
    return {
    }
  },
}

</script>

